@import url('https://fonts.cdnfonts.com/css/inter');

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
}

.join {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 126px;
  color: var(--Black) !important;
  background: var(--White);
  cursor: pointer;
  text-decoration: none;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

@media screen and (max-width: 768px) {
  .join {
    font-size: 12px !important;
  }
}
