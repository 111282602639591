body {
  --Black: #020202;
  --White: #FFFFFF;
  --Gray: #A7AAC6;
  --Green: #4CA19A;
  --Dark-gray: #1A1A1A;
  --Dark-blue: #0C0E13;
  --Medium-gray: #373A43;
  --Medium-blue: #0F1116;
  --Light-gray: #666A6F;
  --textWidthDesktop: 750px;
}
